/* eslint-disable operator-linebreak */
import React, { useCallback, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { useHistory } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { FirstLoadOfInvoices, toggleSiiForm } from '../../apollo/reactive-variables';
import { useAlertMessage, useSelectedCompany } from '../../hooks';
import { closeSiiForm } from '../../helpers/sii-credentials-form-toggle';
import FingoAlert from './alerts/FingoAlert';
import NationalTaxServiceCredentialsDialog from './NationalTaxServiceCredentialsDialog';

const SiiDialog = ({ closeCallback }) => {
  const { t } = useTranslation();
  const { open } = useReactiveVar(toggleSiiForm);
  const history = useHistory();
  const selectedCompany = useSelectedCompany();
  const [alertMessage, setAlertMessage] = useAlertMessage();

  const onCompletedHandler = useCallback(() => {
    FirstLoadOfInvoices(true);
    closeSiiForm(selectedCompany);
  }, [history]);

  const onErrorHandler = useCallback((error) => {
    let errorMessage = '';
    if (error.message === 'invalid_credentials') {
      errorMessage = 'Credenciales inválidas, prueba con unas diferentes';
    } else {
      errorMessage =
        'Hubo un error al verificar tu empresa. Contáctate con nosotros en contacto@fingo.cl';
    }
    setAlertMessage({
      message: errorMessage,
      severity: 'error',
      show: true,
    });
  }, []);

  const setAlertByCurrentCredentials = () => {
    if (selectedCompany?.hasSiiCredentials) {
      setAlertMessage({
        message: `Ya tienes tus credenciales del ${t(
          'National Tax Service acronym',
        )}. Si lo deseas puedes subir otras.`,
        severity: 'success',
        show: true,
      });
    } else {
      setAlertMessage({
        message: `Aun no tienes tus credenciales del ${t(
          'National Tax Service acronym',
        )}, súbelas para ver tus facturas en tiempo real.`,
        severity: 'error',
        show: true,
      });
    }
  };

  const closeSiiDialog = () => {
    closeSiiForm(selectedCompany);
    closeCallback();
    setAlertByCurrentCredentials();
  };

  useEffect(() => {
    setAlertByCurrentCredentials();
  }, [selectedCompany]);

  return (
    <Dialog
      open={open}
      onClose={closeSiiDialog}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: { borderRadius: 12 },
      }}
    >
      <FingoAlert alert={alertMessage} setAlert={setAlertMessage} />
      <NationalTaxServiceCredentialsDialog
        onCompleted={onCompletedHandler}
        onError={onErrorHandler}
      />
      <IconButton
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 1,
          top: 1,
          color: { xs: 'gray', md: 'white' },
        }}
        onClick={closeSiiDialog}
      >
        <Close />
      </IconButton>
    </Dialog>
  );
};

SiiDialog.propTypes = {
  closeCallback: PropTypes.func,
};

SiiDialog.defaultProps = {
  closeCallback: () => {},
};

export default SiiDialog;
